import axios from 'axios';
import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import Item from './item';
import Loader from './../general/loader';

class Category extends Component {

    settings = this.props.Settings;

    state = {
        categories: [],
        load: false,
        back: false
    }

    async componentDidMount() {
        const {data} = await axios.get("https://manage.luxurycafe.net/api/categories");
        this.setState({ categories: data }, ()=>{
            this.setState({load: true});
        });
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e.target.value);
        console.log(e.target.value);
        console.log(this.props.Language);
    }

    headCover = {'backgroundImage':`url(${this.settings.categoryCover})`, 'backgroundSize':'cover'}
    categoryCover = {'backgroundSize': 'cover', 'backgroundPosition': 'center'}

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head" style={this.headCover}>
                            <Link to={"/"}>
                                {/* onClick={this.back} */}
                                <i  className="backward fa-solid fa-arrow-left"></i>
                            </Link>
                                                   
                        <img src={this.settings.logo} alt="" />
                    </div>
                    <div className="info">
                        <b>{this.settings.name}</b>
                        <div className='address'>
                            <div>
                                <i className="fa-solid fa-map-location-dot"></i><span>{this.settings.address}</span>
                            </div>
                            <div className='phone'>
                                <i className="fa-solid fa-phone"></i> 
                                <div>
                                    <span>{this.settings.phone}</span>
                                    <span>{this.settings.phone_2}</span>
                                </div>
                            </div>
                        </div>

        
                    </div>
                    

                    

                    <div className="category-list">

                        {
                            this.state.load 
                            ?
                                this.state.categories.map( (category, index) => <Item key={index} Data = {category} Language = {this.props.Language} Index={index} Length={this.state.categories.length} ></Item>)
                            : 
                                <Loader></Loader>
                        }

                    </div>

                </div>
            </>
        );
    }
}

export default Category;